import 'moment-timezone';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CustomAccordion from '../../../../../components/CustomAccordion/CustomAccordion';
import BusinessUnitParamsContext from '../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { ServiceData } from '../../../../../models/ServiceDataModel';
import { isAuthenticate } from '../../../../../util/commons';
import { AccordionActions } from '../AccordionActions/AccordionActions';
import { ServiceStepContentAuth } from '../ServiceStepContentAuth/ServiceStepContentAuth';
import { CreatedServiceStepContent } from './CreatedServiceStepContent/CreatedServiceStepContent';

type Props = {
  serviceData: ServiceData | undefined;
  cardTitle?: string | undefined;
};

export const CreatedServiceStep: FC<Props> = ({ serviceData, cardTitle }) => {
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const { t } = useTranslation();
  const isAuthenticateUser: any = isAuthenticate(businessUnitUUID);

  const getActionsAccordion = () => {
    return <AccordionActions />;
  };

  return (
    <CustomAccordion
      expandedDefault={isAuthenticateUser}
      expandIcon={isAuthenticateUser ? <ExpandMoreIcon /> : null}
      title={cardTitle ? cardTitle : t('Información del servicio')}
      detailsChildren={
        <>
          {isAuthenticateUser ? (
            <ServiceStepContentAuth serviceData={serviceData}></ServiceStepContentAuth>
          ) : (
            <CreatedServiceStepContent serviceData={serviceData}></CreatedServiceStepContent>
          )}
        </>
      }
      actionsChildren={getActionsAccordion()}
    />
  );
};
