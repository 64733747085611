import { Link } from '@mui/material';
import { FC } from 'react';

import { useStyles as useStylesGlobal } from '../../../../../styles/global/stylesGlobal';
import { useStyles } from './styles';

export const AccordionActions: FC = () => {
  const classesGlobal = useStylesGlobal();
  const classes = useStyles();

  const onClick = () => {
    console.log('Ver detalle');
  };

  return (
    <div className={classes.container}>
      <Link underline="none" className={classesGlobal.link} color="inherit" onClick={onClick}>
        {'Ver detalle'}
      </Link>
    </div>
  );
};
